import React from 'react'
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'

import { TemplateControlsContainer } from './TemplateControls.styles'

const TemplateControls = ({ onPreview, onEdit, user, hasAccess }) => (
  <TemplateControlsContainer>
    {user && hasAccess && (
      <Button
        variant='contained'
        disableElevation
        color='primary'
        size='small'
        onClick={onEdit}
      >
        Customize
      </Button>
    )}
    <IconButton
      onClick={onPreview}
      size='small'
      startIcon={<VisibilityOutlined />}
      style={{ color: 'white' }}
    >
      <VisibilityOutlined />
    </IconButton>
  </TemplateControlsContainer>
)

export default TemplateControls
