import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import ThumbUpOutlined from '@material-ui/icons/ThumbUpOutlined'
import ThumbUp from '@material-ui/icons/ThumbUp'
import ThumbDown from '@material-ui/icons/ThumbDown'
import FavoriteBorder from '@material-ui/icons/FavoriteBorder'
import Favorite from '@material-ui/icons/Favorite'
import ThumbDownOutlined from '@material-ui/icons/ThumbDownOutlined'
import {
  toggleFavoriteMutation,
  toggleLikeMutation
} from '../../graphql/templates'
import { reactionStatuses } from '../../lib/constants/template'
import {
  TemplateReactionsContainer,
  ReactionButton
} from './TemplateReactions.styles'

const TemplateReactions = ({
  refetchFavorites,
  reactions: { id, userLike, favorited }
}) => {
  const { LIKE, DISLIKE, INDIFFERENT } = reactionStatuses

  const [toggleFavorite] = useMutation(toggleFavoriteMutation, {
    onCompleted: () => refetchFavorites(),
    onError: (e) => console.log('error', e)
  })
  const [toggleLike] = useMutation(toggleLikeMutation, {
    onError: (e) => console.log('error', e)
  })

  const handleLikeAction = async (action) => {
    if (!userLike || userLike.status !== action) {
      await toggleLike({
        variables: { id, status: action }
      })
    } else {
      await toggleLike({
        variables: { id, status: INDIFFERENT }
      })
    }
  }

  const handlefavoriteAction = async () => {
    await toggleFavorite({ variables: { id } })
  }

  return (
    <TemplateReactionsContainer>
      <ReactionButton onClick={() => handleLikeAction(LIKE)}>
        {userLike && userLike.status === LIKE ? (
          <ThumbUp />
        ) : (
          <ThumbUpOutlined />
        )}
      </ReactionButton>
      <ReactionButton onClick={() => handleLikeAction(DISLIKE)}>
        {userLike && userLike.status === DISLIKE ? (
          <ThumbDown />
        ) : (
          <ThumbDownOutlined />
        )}
      </ReactionButton>
      <ReactionButton onClick={handlefavoriteAction}>
        {favorited ? <Favorite /> : <FavoriteBorder />}
      </ReactionButton>
    </TemplateReactionsContainer>
  )
}
export default TemplateReactions
