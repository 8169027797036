import { styled } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'

export const TemplateReactionsContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  color: 'white',
  cursor: 'default',
  zIndex: '3',
  width: 'fit-content'
}))

export const ReactionButton = styled(IconButton)(() => ({
  color: 'white'
}))
