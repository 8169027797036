import { styled } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'

export const TemplateContainer = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  margin: '0.5rem',
  overflow: 'hidden',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3]
}))

export const TemplateOverLay = styled('div')(() => ({
  position: 'absolute',
  zIndex: 1,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.3)'
}))

export const StyledChip = styled(Chip)(({ theme, color }) => ({
  backgroundColor: color || theme.palette.secondary.main
}))
