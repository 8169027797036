import { styled } from '@material-ui/core'

export const TemplateControlsContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'space-between',
  width: '-webkit-fill-available',
  color: 'white',
  cursor: 'default',
  zIndex: '2',
  padding: theme.spacing(2)
}))
